<template>
    <div class="site__centered">

        <p v-if="isLoading"></p>

        <div
            v-else
            class="application form"
        >
            <h2 class="application__title">Task Form</h2>

            <div class="application__progress">
                <div class="application__progress-step">
                    <span class="application__progress-step-done">Task Step {{curTab}} </span>
                    <span class="application__progress-step-total">/{{stepsList.length}}</span>
                </div>
                <div class="application__progress-bar">
                    <span
                        class="application__progress-bar-percentages"
                        :style="{width: curTab*100/(stepsList.length) +'%'}"
                    ></span>
                </div>
           </div>

            <div v-if="curTab <= stepsList.length">
                <template
                    v-for="(step, index) in stepsList"
                >
                    <div
                        v-if="curTab === index + 1"
                        :key="index"
                    >
                        <div
                                v-for="(item, index) in step"
                                v-show="!item.hidden"
                                :key="item.frontID"
                        >
                            <ApplicationAddRow
                              :key="item.frontID"
                              :ref="item.ref"
                              :fields-data="item"
                              :field-value="item.value"
                              :field-index="index"
                              :valid-tracker="validTracker"
                              :check-value="item.hidden"
                              :is-disabled-editing="false"
                              @checkConditions = "checkConditions"
                            />
                        </div>

                        <div v-if="paymentFields.length">
                          <div class="payment-wizard-total">
                            <span>Total</span>
                            <span>($){{ totalSum }}</span>
                          </div>
                        </div>
                        <NewCreditCard
                          v-if="paymentFields.length"
                          ref="credit-card"
                        />
                    </div>
                </template>

                <p class="form__error">{{errorMessage}}</p>

                <div class="form__row form__one-row">
                    <button
                        v-if="curTab > 1"
                        class="btn btn--color-outline-inactive form__button input-button_left"
                        @click="curTab -= 1"
                    >Previous</button>
                    <button
                            v-if="curTab < stepsList.length"
                            class="btn btn--color-dark form__button"
                            @click="validStep"
                    >Next</button>
                    <template>
                    <button
                            v-if="curTab === stepsList.length"
                            class="btn btn--color-dark form__button"
                            @click="saveForm"
                            :disabled="isButtonDisabled"
                    >Complete Form</button>
                    </template>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
  import router from '@/router'
  import store from '@/store'
  import { onUnmounted } from '@vue/composition-api'
  import { mapGetters } from 'vuex'
  import 'vue-select/dist/vue-select.css'

  import ApplicationAddRow from '@/views/Application/ApplicationAddRow.vue'
  import NewCreditCard from '@/components/site/NewCreditCard.vue'

  import storeModule from '@/views/Tasks/tasksStoreModule'
  import useApplicationAdd from '@/views/Application/useApplicationAdd'
  import useApplicationTaskAdd from '@/views/Tasks/useApplicationTaskAdd'
  import {
    hasValue,
    designateValue,
    designateOptions,
    transformField,
    checkHiddenAttr,
  } from '@/helpers/formConditions'

  export default {
    components: {
      ApplicationAddRow,
      NewCreditCard,
    },
    data() {
      return {
        groupDetail: {},
        applicationTaskID: null,
        applicationTask: {},
        applicationData: {},
        stepsList: [],
        allConditions: [],
        fileField: false,
        hasSignatureField: false,
        file: null,
        curTab: 1,
        error: [],
        validTracker: false,
        errorMessage: '',
        isButtonDisabled: false,
      }
    },
    setup() {
      const INVOICE_APP_STORE_MODULE_NAME = 'app-tasks'

      // Register module
      if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
      })

      const {
        fetchForm,
        uploadFiles,
      } = useApplicationAdd()

      const {
        postTaskExecute,
        fetchApplicationTask,
        fetchApplicationTaskGroupOptions,
        fetchBaseApplicationData,
      } = useApplicationTaskAdd()

      return {
        fetchForm,
        uploadFiles,
        postTaskExecute,
        fetchApplicationTask,
        fetchApplicationTaskGroupOptions,
        fetchBaseApplicationData,
      }
    },
    async created() {
      store.commit('SET_LOADING_STATUS', true)
      this.applicationTaskID = router.currentRoute.params.id

      const applicationId = router.currentRoute.params.application_id
      if (applicationId) {
        this.applicationData = await this.fetchBaseApplicationData(applicationId)
        store.commit('applicationRegistration/SET_APPLICATION_ID', applicationId)
        store.commit('applicationRegistration/SET_CHOSEN_SESSIONS', this.applicationData.sessions)
      }
      if (this.applicationTaskID) {
        await this.fetchApplicationTask(this.applicationTaskID)
          .then(response => {
            this.setApplicationTask(response)
          })
      }
      store.commit('SET_LOADING_STATUS', false)
    },
    methods: {
      setApplicationTask(val) {
        if (!val) return
        this.applicationTask = val

        if (val.task?.form?.id) {
          this.applicationTask.form_id = val.task.form.id
        }

        if (val.task?.program?.id) {
          store.commit('applicationRegistration/SET_PROGRAM_ID', val.task.program.id)
        }

        this.fetchForm(val.form_id)
          .then(response => {
            this.allConditions = response.conditions
            this.stepsList = []
            let newStep = []

            response.fields.forEach((row, index) => {
              if (row.type === 'file_upload') this.fileField = true
              if (row.type === 'signature') this.hasSignatureField = true

              const ref = row.name + index
              if (row.type === 'step') {
                this.stepsList.push(newStep)
                newStep = []

                return
              }

              if (row.type === 'group_selection') {
                newStep.push({
                  id: row.id,
                  ref: ref,
                  value: null,
                  label: row.label,
                  name: row.name,
                  default: row.default,
                  options: [],
                  type: transformField(row.type),
                  required: row.required,
                  hide_price: row.hide_price,
                  hidden: checkHiddenAttr(row.conditions.filter(item => item.action === 'Show')),
                })
              } else if (row.type === 'payment_field') {
                newStep.push({
                  id: row.id,
                  ref: ref,
                  name: row.label,
                  type: transformField(row.type),
                  charge_amount: row.options.charge_amount,
                  description: row.options.description,
                  payment_plan_is_enable: row.options.payment_plan_is_enable,
                  max_months: row.options.max_months,
                  payment_plan_activated: false,
                  payment_month_selected: null,
                  field_id: row.id,
                  hidden: checkHiddenAttr(row.conditions.filter(item => item.action === 'Show')),
                })
              } else {
                newStep.push({
                  id: row.id,
                  ref: ref,
                  value: designateValue(row.type, row.default),
                  label: row.label,
                  name: row.name,
                  options: designateOptions(row.type, row.options),
                  type: transformField(row.type),
                  required: row.required,
                  hidden: checkHiddenAttr(row.conditions.filter(item => item.action === 'Show')),
                })
              }

              if (index+1 === response.fields.length) {
                this.stepsList.push(newStep)
              }
            })
          })
      },
      uploadFile(file) {
        const formData = new FormData()
        formData.append('file', file)
        return this.uploadFiles(`tasks-files-${new Date().getTime()}`, formData)
      },
      checkConditions() {
        const allFields = this.stepsList.flat()

        this.allConditions.forEach(condition => {
          let isMatched
          for (let i = 0; i < condition.scoped_conditions.length; i++) {
            const scopeItems = condition.scoped_conditions[i];
            const scopeItemsResults = scopeItems.map(item => {
              const foundedField = allFields.find(field => field.id === item.field_id)
              const result = hasValue(item, foundedField.value)
              return result
            })
            isMatched = scopeItemsResults.every(i => i === true)
            if (isMatched || i == condition.scoped_conditions.length - 1) {
      
              if (condition.related_type === 'Field') {
                const field = allFields.find(field => field.id === condition.related_id)
                
                if (field) {
                  field.hidden = condition.action === 'Show' ? !isMatched : isMatched
                  if (!isMatched) {
                    field.value = designateValue(field.type, field.value, field.options)
                  }
                }
              }
              break
            }
          }
        })
      },
      async validStep() {
        if ( this.stepsList[this.curTab - 1].filter(row => row.required && !row.value && !row.hidden && !['plain_text', 'signature'].includes(row.type)).length > 0 ) {
          this.errorMessage = 'Please, fill required fields'
          this.validTracker = true
          return
        }

        const signatureFields = this.stepsList[this.curTab - 1].filter(field => field.type === 'signature' && field.required)
        if (signatureFields.length > 0 ) {
          signatureFields.forEach(field => {
            const canvasRef = this.findCanvasRefForField(field)
            if (canvasRef && canvasRef.isEmpty() && !canvasRef.loadedImage) {
              this.errorMessage = 'Please, fill Signature field'
              this.validTracker = true
            }
          })
          if (this.validTracker) {
            return
          }
        }

        if (this.paymentFields.length) {
          if (this.$refs['credit-card'][0].$v.$invalid){
            this.validTracker = true
            await this.$refs['credit-card'][0].$v.$touch();
            document.querySelector('.is-invalid').focus()
            return
          }
        }

        this.validTracker = false

      this.errorMessage = ''
      if (this.curTab < this.stepsList.length){
        this.curTab += 1
      }
    },
    findCanvasRefForField(field) {
      return this.$refs[field.ref][0]?.$refs[`signature_${field.ref}`]?.$refs[field.ref]
    },
    async saveForm() {
      this.validTracker = false
      this.validStep()
      if (this.validTracker) return

      store.commit('SET_LOADING_STATUS', true)
      this.isButtonDisabled = true

        let fileFieldsCount = 0
        const queryParams = {
          form_id: this.applicationTask.form_id,
          fields: [],
          form_data: {},
        }
        const filesLinks = []
        const filesNames = []

      if (this.fileField) {
        const fileFields = this.stepsList.flat(1).filter(row => row.type === 'file_upload' && row.value)

        if (fileFields.length > 0) {
          fileFields.forEach(file => {
            Array.from(file.value).forEach(val => {
              filesLinks.push(this.uploadFile(val))
            })
          })
        }
      }

      if (this.paymentFields.length) {
        queryParams.charges = this.paymentFields
        const cardData = this.$refs['credit-card'][0].cardData

        if (cardData) {
          queryParams.card = {
            number: cardData.number,
            exp_month: cardData.expMonth,
            exp_year: cardData.expYear,
            cvc: cardData.cvc,
          }
        }
      }

      if (this.hasSignatureField) {
          const signatureFields = this.stepsList.flat(1).filter(row => row.type === 'signature')
          signatureFields.forEach(field => {
            const canvasRef = this.findCanvasRefForField(field)
            if (canvasRef && !canvasRef.isEmpty()) {
              field.signature = canvasRef.save()
            }
          })
        }

      Promise.all(filesLinks)
          .then(responses => responses.forEach(
            response => {
              if (!response.file_path) {
                store.commit('SET_LOADING_STATUS', false)
                this.errorMessage = 'Error uploading file'
                throw new Error('Error')
              }
              filesNames.push(response.file_path)
            }))
          .then(() => {
            queryParams.groups_id = this.taskGroups
              .filter(group => group.value !== null)
              .map(group => {
                if (group.value.id) {
                  return group.value.id
                } else if (group.value.length > 0) {
                  return group.value.map(value => value.id)
                }
              })
              .flat()

            this.stepsList.forEach(step => {
              step
                .filter(row => !row.hidden)
                .forEach(row => {
                  if (row.type === 'file_upload' && row.value) {
                    let from = fileFieldsCount
                    let to = fileFieldsCount + row.value.length
                    let sliced = filesNames.slice(from, to)

                    queryParams.fields.push({
                      field_id: row.id,
                      files: sliced,
                    })
                    queryParams.form_data[row.name] = sliced

                    fileFieldsCount = to
                  } else if(row.type === 'group_selection') {
                    let value
                    if (Array.isArray(row.value)) {
                      value = row.value.map(item => ({ id: item.id, name: item.name }))
                    } else {
                      value = { id: row.value?.id, name: row.value?.name }
                    }
                    queryParams.fields.push({
                      field_id: row.id,
                      result: value,
                    })
                    queryParams.form_data[row.name] = row.value
                  } else if (row.type === 'payment_field') {
                    queryParams.fields.push({
                      field_id: row.id,
                      result: {
                        payment_plan_activated: row.payment_plan_activated,
                        payment_month_selected: row.payment_month_selected,
                        charge_amount: row.charge_amount,
                      },
                    })
                  } else if (row.type === 'signature' && row.signature) {
                    queryParams.fields.push({
                      field_id: row.id,
                      result: row.signature,
                    })
                    queryParams.form_data[row.name] = row.signature
                  } else {
                    queryParams.fields.push({
                      field_id: row.id,
                      result: row.value,
                    })
                    queryParams.form_data[row.name] = row.value
                  }
                })
            })

            queryParams.application_task_id = this.applicationTaskID
            this.postTaskExecute(queryParams).then(() => {
              store.commit('SET_LOADING_STATUS', false)
              this.$router.replace({ name: 'tasks' })
            })
          })
      },
    },
    computed: {
      ...mapGetters({
        isLoading: 'getLoadingStatus',
      }),
      taskGroups() {
        return this.stepsList.flat().filter(row => row.type === 'group_selection')
      },
      paymentFields() {
        return this.stepsList.flat().filter(field => field.type === 'payment_field' && !field.hidden)
      },
      totalSum() {
        return this.paymentFields.reduce((acc, cur) => {
          return acc + Number(cur ? cur.charge_amount : 0)
        }, 0)
      },
    }
  }
</script>

<style lang="scss">
@import 'src/assets/scss/colors/colors';

.vs__dropdown-menu {
    li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $secondary;

        span:nth-child(1){
            position: relative;
            padding-left: 32px;

            &:before,
            &:after {
                content: '';
                position: absolute;
            }

            &:after {
                top: 21px;
                left: 0;
                width: 20px;
                height: 20px;
                border: 1px solid #4C597D;
                border-radius: 5px;
                background-color: #fff;
                transition: background-color .3s ease, border-color .3s ease;
            }

            &:before {
                top: 24px;
                left: 7px;
                width: 6px;
                height: 11px;
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
                transform: rotate(45deg);
                transition: opacity .3s ease;
                z-index: 1;
            }
        }

        &.vs__dropdown-option--selected {

            span:nth-child(1){

                &:after {
                    background-color: #EDA400;
                    border-color: #EDA400;
                }
            }
        }

        &.vs__dropdown-option--highlight {
            background-color: transparent;
            color: $secondary_bg;
        }

        &.vs__dropdown-option--disabled {
            filter: grayscale(1);
            opacity: .5;
        }
    }
}
.select {
    .vs__dropdown-menu {
        border-radius: 10px;
        padding-top: 0;
        padding-bottom: 0;
        li {
            height: 50px;

            &.vs__dropdown-option--highlight {
                background-color: #EDA400;
                color: #fff;
            }
        }
    }
    .vs__dropdown-toggle {
        border: none;
        height: 60px;
        padding-left: 20px;
        padding-right: 20px;
        background: #EDEDED;
        border-radius: 15px;
    }
    .vs__selected-options input::placeholder {
        font-size: 16px;
        line-height: 20px;
        color: rgba($secondary, 0.808);
    }
}
.is-invalid{
  color: #f00;
}
    
</style>
