import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useApplicationTaskAdd() {
  // Use toast
  const toast = useToast()

  const postTaskExecute = queryParams => axios
    .post('/tasks/execute', queryParams)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Task was successfully completed',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error saving Task',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchApplicationTask = ID => axios
    .get(`/tasks/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching task info',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchApplicationTaskGroupOptions = queryParams => axios
    .post(`/applications/groups-by-application`, queryParams )
    .then(response => response.data.data)
    .catch()

  const fetchBaseApplicationData = ID => axios
    .get(`/applications/${ID}/base-data`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching application data',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  return {
    postTaskExecute,
    fetchApplicationTask,
    fetchApplicationTaskGroupOptions,
    fetchBaseApplicationData,
  }
}
